import { createTheme } from '@mui/material';
const defaultTheme = createTheme();

const theme = createTheme({
  spacing: 5,
  typography: {
    fontFamily: `'Poppins', sans-serif`,
    h1: {
      color: defaultTheme.palette.common.white,
    },
    h4: {
      fontSize: defaultTheme.typography.pxToRem(25),
    },
    h6: {
      color: defaultTheme.palette.common.white,
      fontSize: defaultTheme.typography.pxToRem(16),
      fontWeight: 600,
      lineHeight: '24px',
    },
    body1: {
      fontSize: defaultTheme.typography.pxToRem(16),
    },
    body2: {
      fontSize: defaultTheme.typography.pxToRem(12),
      color: defaultTheme.palette.common.white,
    },
    button: {
      fontSize: defaultTheme.typography.pxToRem(14),
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: defaultTheme.typography.fontWeightMedium,
          fontSize: defaultTheme.typography.pxToRem(12),
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
  palette: {
    grey: {
      A100: '#F5F4F4',
      400: '#0000006b',
      500: 'rgba(0, 0, 0, 0.15)',
      600: '#5B5B5B',
    },
    success: {
      main: '#27AE60',
      contrastText: 'white',
      darker: '#19703e',
    },
    primary: {
      main: '#009999',
      light: 'rgba(0, 153, 153, 0.45)',
      darker: '#006464',
    },
    neutral: {
      main: '#80cbc4',
      light: '#C5E8D8',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.6)',
    },
    black: {
      main: '#000000',
      darker: 'rgba(0, 0, 0, 0.87)',
      light: 'rgba(0, 0, 0, 0.6)',
    },
    secondary: {
      main: '#002699',
    },
  },
});

export default theme;
