import { ROUTES } from '@/constants';
import { User } from '@/models';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

export const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const undefined_string = 'undefined'; // cookies may contain this value if it was stored without checking

function cookies_get(key: string): string {
  const value = Cookies.get(key);
  if (value === undefined_string) {
    return undefined;
  }
  return value;
}

http.interceptors.request.use(
  function (config) {
    let user: User;
    const token = cookies_get('access_token');
    let user_cookie = cookies_get('user');
    if (user_cookie) {
      user = JSON.parse(user_cookie);
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (user && user?.customer_id) {
      config.headers['X-customer-context'] = user?.customer_id;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (
      error?.response?.status === 401 &&
      !window.location.pathname.includes('login')
    ) {
      Cookies.remove('access_token');
      Cookies.remove('user');
      window.location.href = ROUTES.LOGIN;
    }

    return Promise.reject(error);
  }
);
