import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { ROUTES } from './constants';

const NotFound = lazy(() => import('./pages/not-found'));
const Home = lazy(() => import('./pages/home'));
const Project = lazy(() => import('./pages/project'));
const AddOrEditPour = lazy(() => import('./pages/add-or-edit-pour'));
const AddHub = lazy(() => import('./pages/add-hub'));
const AddSensor = lazy(() => import('./pages/add-sensor'));
const AddSensorByName = lazy(() => import('./pages/add-sensor-by-name'));
const Sensor = lazy(() => import('./pages/sensor'));
const Sensors = lazy(() => import('./pages/pour-sensors'));
const Settings = lazy(() => import('./pages/settings'));
const EditSensor = lazy(() => import('./pages/edit-sensor'));

const routes: RouteObject[] = [
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.PROJECT,
    element: <Project />,
  },
  {
    path: ROUTES.ADD_POUR,
    element: <AddOrEditPour />,
  },
  {
    path: ROUTES.EDIT_POUR,
    element: <AddOrEditPour isEdit />,
  },
  {
    path: ROUTES.ADD_HUB,
    element: <AddHub />,
  },
  {
    path: ROUTES.ADD_SENSOR,
    element: <AddSensor />,
  },
  {
    path: ROUTES.ADD_SENSOR_BY_NAME,
    element: <AddSensorByName />,
  },
  {
    path: ROUTES.SENSORS,
    element: <Sensors />,
  },
  { path: ROUTES.SENSOR, element: <Sensor /> },
  { path: ROUTES.SETTINGS, element: <Settings /> },
  { path: ROUTES.EDIT_SENSOR, element: <EditSensor /> },

  {
    path: '*',
    element: <NotFound />,
  },
];
export default routes;
