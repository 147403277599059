import { create } from 'zustand';
import Cookies from 'js-cookie';
import {
  ILoginPayload,
  ILoginResponse,
  ResponseType,
  IResetPasswordPayload,
  IChangeSettingPayload,
  User,
} from '@/models';
import { API_ENDPOINTS, LoadingKeys } from '@/constants';
import { http } from '@/utils';
import { handleApiCallInStore } from './utils';
import { t } from 'i18next';

const user = () => {
  const currentUser = Cookies.get('user');
  const access_token = Cookies.get('access_token');

  if (currentUser && currentUser !== 'undefined') {
    return { user: JSON.parse(currentUser), access_token };
  }
};

export interface IAppState {
  currentUser?: ILoginResponse;
  login: (payload: ILoginPayload, onSuccess: () => void) => void;
  logout: (onSuccess: () => void) => void;
  resetPassword: (payload: { email: string }, onSuccess: () => void) => void;
  updateInfo: (
    userId: number,
    payload: IChangeSettingPayload,
    onSuccess: (values: IChangeSettingPayload) => void
  ) => void;
}

export const useAppStore = create<IAppState>((set, get) => ({
  currentUser: user(),
  login: (payload, onSuccess) => {
    handleApiCallInStore(
      LoadingKeys.Login,
      async () => {
        const { email, password, keepSign } = payload;
        const { data } = await http.post<
          ILoginPayload,
          ResponseType<ILoginResponse>
        >(API_ENDPOINTS.auth.login, {
          email,
          password,
        });
        const { access_token, user } = data;
        // If we got undefined something went wrong, do not store invalid cookies
        if (access_token && user) {
            Cookies.set('access_token', access_token, {
              expires: keepSign ? 7 : 1,
            });
            Cookies.set('user', JSON.stringify(user), {
              expires: keepSign ? 7 : 1,
            });
        }
        set(() => ({ currentUser: data }));
        onSuccess?.();
      },
      t('wrongEmailOrPassword')
    );
  },
  logout: async onSuccess => {
    await Cookies.remove('access_token');
    await Cookies.remove('user');
    set(() => ({ currentUser: undefined }));
    onSuccess?.();
  },
  
  resetPassword: async (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.ResetPassword, async () => {
      await http.post<IResetPasswordPayload>(
        API_ENDPOINTS.auth.resetPassword,
        payload
      );
      onSuccess?.();
    });
  },
  
  updateInfo: async (userId, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.UpdateInfo, async () => {
      const { password, ...otherValues } = payload;
      await http.put<IChangeSettingPayload>(
        API_ENDPOINTS.auth.updateInfo(userId),
        payload
      );
      Cookies.set('user', JSON.stringify(otherValues));

      set(() => ({
        currentUser: { ...get().currentUser, user: otherValues as User },
      }));
      onSuccess?.(payload);
    });
  },
}));
