import { ConcreteStrengthType } from '@/models';

export const generateInitials = (name: string) => {
  if (name) {
    const nameParts = name.trim().split(' ');
    const initials = nameParts.reduce((acc, part) => acc + part.charAt(0), '');
    return initials.toUpperCase();
  } else {
    return '';
  }
};
export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));
export const celsiusToFahrenheit = (value: number) => {
  return value * 1.8 + 32;
};

export const convertBTypeToStrength = (bType: ConcreteStrengthType) =>
  Number(bType.replace('B', ''));

  export const cubeToCelinderandPsi = (strength: number, isMetric: boolean, isCylinder: boolean) => {
    let ret = strength;
    if(isCylinder){
      ret = cubeToCelinder(ret)
    }
    if(!isMetric){
      ret = mpaToPsi(ret)
    } 
    return ret;
  }

export const mpaToPsi = (strength: number) => {
  if (strength !== undefined && strength !== null) {
    return strength * 145.0377;
  } else {
    return 0;
  }
};

export const cubeToCelinder = (strength: number) => {
  if (strength !== undefined && strength !== null) {
    try{

        if(strength < ((1.319+1.319)/0.996)){
          return strength/((1.319+1.319)/0.996);
        }else{
          return strength*0.996 -1.319;
        }
        // if(strength > 1){
        //   return strength-1;
        // }
        // else return 0;
        // return strength + 0.3;
        
        
    }catch{
      return strength;
    }
  }return strength;
};
