import { toast } from 'react-toastify';
import { LoadingKeys } from '@/constants';
import { loadingStore } from './loading.store';
import { AxiosError } from 'axios';

export const handleApiCallInStore = async (
  loadingKey: LoadingKeys,
  apiCall: () => void,
  errorMessage?: string,
  onFail?: () => void
) => {
  try {
    loadingStore.getState().setLoading(loadingKey, true);
    await apiCall();
  } catch (error) {
    if (onFail) {
      onFail?.();
    }
    if (error instanceof AxiosError) {
      const message = error?.response?.data?.message || 'Something went wrong';
      toast.error(errorMessage ?? message);
    }
  } finally {
    loadingStore.getState().setLoading(loadingKey, false);
  }
};
