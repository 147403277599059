import React, { useEffect } from 'react';
import { version } from '../../src/version';
import { useProjectStore } from '@/stores';
import { http } from '@/utils';
import { API_ENDPOINTS } from '@/constants';


const BackgroundTask: React.FC = () => {
  var { pwaVersion } = useProjectStore();

  
//   const last_version = getLoading(LoadingKeys.pwaVersion)
  useEffect(() => {
    console.log(pwaVersion);
    const intervalId = setInterval(async () => {
      const data:any = await http.get<{ pwaVersion: any }>(
            API_ENDPOINTS.project.pwaVersion
          );
      console.log(`my version is ${version}`);
      console.log(`and last version is ${data.data}`);
      if((version.split(".").map(x => parseInt(x))) < (data.data.split(".").map(x => parseInt(x)))){
        console.log('my version is not updateted');
        window.location.reload();
      }

    }, (2*60*1000)); // 1000 milliseconds = 1 seconds (every 2 minutes)

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, []);

  return null; // The component doesn't render anything
};

export default BackgroundTask;
