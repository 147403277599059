import { lazy, Suspense } from 'react';
import { ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import theme from './theme';
import { ToastContainer } from 'react-toastify';
import { Loader, PrivateRoute, SuspenseLoader } from '@/components';
import routes from './router';
import { ROUTES } from './constants';
import  BackgroundTask  from './components/BackgroundTask'
import './app.css';
import ProjectStatus from './pages/project-status'
import EditHub from './pages/edit-hub'


const Login = Loader(lazy(() => import('./pages/login')));

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<SuspenseLoader />}>
      <BackgroundTask></BackgroundTask>
        <Routes>

          <Route element={<PrivateRoute />}>
            {routes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>

          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.PROJECT_STATUS} element={<ProjectStatus />} />
          <Route path={ROUTES.EDIT_HUB} element={<EditHub />} />
        </Routes>
      </Suspense>

      <ToastContainer
        closeOnClick
        autoClose={2000}
        position='top-center'
        theme='colored'
        pauseOnHover
      />
    </ThemeProvider>
  );
};

export default App;
