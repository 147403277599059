import { useAppStore } from '@/stores';
import moment from 'moment';
import { useMemo } from 'react';
import dayjs from 'dayjs';


export const useDateTimeFormat = () => {
  const { currentUser } = useAppStore();
  const dateFormat = useMemo(
    () =>
      currentUser?.user?.date_format === 'dmy' ? 'DD-MM-YYYY' : 'MM-DD-YYYY',
    [currentUser]
  );
  const timeFormat = useMemo(
    () => (currentUser?.user?.time_format === '12h' ? 'hh:mm a' : 'HH:mm'),
    [currentUser]
  );
  return {
    dateFormat: dateFormat,
    timeFormat: timeFormat,
    dateTimeFormat: `${dateFormat} ${timeFormat}`,
    formatDate: (date: any, format: 'date' | 'time') =>
      date != null
        ? dayjs(date).format(
            format === 'date' ? dateFormat : `${dateFormat} ${timeFormat}`
          )
        : 'N/A',
  };
};
