import { useEffect, useState } from 'react';
import { Hub, Page, PopConfirm, Title } from '@/components';
import { LoadingKeys } from '@/constants';
import { loadingStore, useProjectStore } from '@/stores';
import { Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';
import { object, string } from 'yup';
import { toast } from 'react-toastify';

const EditHub = () => {
    const navigate = useNavigate();
    const { hubId, projectId } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { getLoading } = loadingStore();
    const [hubName, setHubName] = useState('');
    const [hubLocation, setHubLocation] = useState('');
    const { editHub, technicalScreenData, getTechnicalScreen } = useProjectStore();

    //console.log('hubId', hubId);
    
    const formik = useFormik({
        initialValues: {
            location: hubLocation, // Initialize with the state value
        },
        validationSchema: object({
            location: string().required(t('thisFieldIsMandatory')),
        }),
        onSubmit: async (values) => {
            try {
                if (hubId) { // Check if hubId is defined
                    await editHub(hubId, values);
                    toast.success('Hub updated successfully');
                    navigate(`/projects/status/${projectId}`);
                } else {
                    toast.error('Hub ID is not defined');
                }
            } catch (error) {
                toast.error('Failed to update hub');
            }
        }
    });

    useEffect(() => {
        if (projectId) {
            getTechnicalScreen(Number(projectId));
        }
    }, [projectId, getTechnicalScreen]);

    useEffect(() => {
        console.log('use Effect only once');
        if (technicalScreenData.length > 0 && hubId) {
            const hub = technicalScreenData[0].project_hubs.find(hub => hub.hub_ti_id === hubId);
            if (hub) {
                if (hub.hub_name !== hubName) {
                    setHubName(hub.hub_name);
                }
                if (hub.location !== hubLocation) {
                    setHubLocation(hub.location); // Update the location state
                    formik.setFieldValue('location', hub.location, false); // Ensure Formik updates its value
                }
            }
        }
    }, [technicalScreenData, hubId]); // Remove formik from the dependency array

    const handleModalConfirm = async () => {
        setIsModalOpen(false);
    };

    return (
        <Page
            isLoading={getLoading(LoadingKeys.EditHub)}
            navTitle={'Edit Hub'}
            withButton
            buttonTitle={t('update')}
            buttonLoading={getLoading(LoadingKeys.EditHub)}
            handleButtonClick={() => formik.submitForm()}
        >
            <Box>
                <Title
                    sx={{ my: '20px' }}
                    icon={<Hub />}
                    title={hubName} // Display hub name
                />
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        sx={{ mb: '20px' }}
                        fullWidth
                        id='location'
                        name='location'
                        label={t('location')}
                        required
                        variant='standard'
                        InputLabelProps={{ shrink: true }}
                        {...formik.getFieldProps('location')}
                        error={formik.touched.location && Boolean(formik.errors.location)}
                        helperText={formik.touched.location && formik.errors.location}
                    />
                </form>
                <PopConfirm
                    isConfirmLoading={getLoading(LoadingKeys.EditHub)}
                    isOpen={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    onConfirm={handleModalConfirm}
                    title={`${'Confirm'}!`}
                    subTitle={'Are you sure you want to update this hub?'}
                />
            </Box>
        </Page>
    );
};

export default EditHub;
