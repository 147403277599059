import { useAppStore } from '@/stores';

export const useUnitType = () => {
  const { currentUser } = useAppStore();
  return {
    isMetric: currentUser?.user?.units === 'mpa',
  };
};
export const useCubeOrCylinder = () => {
  const { currentUser } = useAppStore();
  return {
    isCylinder: currentUser?.user?.press_format === 'cylinder',
  };
};