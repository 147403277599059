import { SxProps, Theme, styled } from '@mui/material/styles';

interface SkeletonBlockProps {
  width?: string;
  height?: string;
  sx?: SxProps<Theme>;
}

export const StyledSkeleton = styled('div')<SkeletonBlockProps>(
  ({ theme, width = '100%', height = '16px' }) => ({
    display: 'inline-block',
    height: width,
    width: height,
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#dddbdd',
    borderRadius: '4px',
    '@keyframes shimmer': {
      '100%': {
        transform: 'translateX(100%)',
      },
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transform: 'translateX(-100%)',
      backgroundImage:
        'linear-gradient( 90deg, rgba(255, 255, 255, 0) 0,rgba(255, 255, 255, 0.2) 20%,rgba(255, 255, 255, 0.5) 60%,rgba(255, 255, 255, 0))',
      animation: 'shimmer 2s infinite',
    },
  })
);

const SkeletonBlock = ({
  width = '100%',
  height = '16px',
  sx = {},
}: SkeletonBlockProps) => {
  return <StyledSkeleton sx={{ ...sx }} width={width} height={height} />;
};

export default SkeletonBlock;
