import { styled, Select, Box } from '@mui/material';
import { ReactNode } from 'react';
import { Search } from '../icons';

export const SelectComp = styled(Select)({
  '& svg': {
    transition: 'all 0.3s ease-in-out',
  },
  '& .MuiSelect-select[aria-expanded="true"]': {
    '&~svg': {
      transform: 'rotate(180deg)',
    },
  },
});

export const SelectSearchIconWrap = styled('div')({
  position: 'absolute',
  top: '0',
  left: '0',
  transform: 'translate(0%,100%)',
});

interface AutoCompleteTextFieldWrapProps {
  children: ReactNode | ReactNode[];
}
export const AutoCompleteTextFieldWrap = ({
  children,
}: AutoCompleteTextFieldWrapProps) => (
  <Box
    sx={{
      position: 'relative',
      '& .MuiInput-input.MuiInput-input': {
        paddingLeft: '30px',
      },
    }}
  >
    <SelectSearchIconWrap>
      <Search />
    </SelectSearchIconWrap>
    {children}
  </Box>
);
