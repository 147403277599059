import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  AddHubPayload,
  AddSensorPayload,
  IChartDataItem,
  IEditSensorPayload,
  IHubQrDetailsRes,
  ISensorDetails,
  ISensorItem,
  ISensorQrDetailsRes,
} from '@/models';
import { celsiusToFahrenheit, http, mpaToPsi, cubeToCelinder } from '@/utils';
import { API_ENDPOINTS, LoadingKeys } from '@/constants';
import { handleApiCallInStore } from './utils';
import { t } from 'i18next';

export interface ISensorState {
  pourSensors: ISensorItem[];
  sensorDetails: ISensorDetails;
  sensorData: {
    temperatureData: IChartDataItem[];
    strengthData: IChartDataItem[];
    resistivityData: IChartDataItem[];
    displayName: string;
  };
  getPourSensors: (pourId: string, isFinished: boolean) => void;
  getSensorData: (pourId: number, sensorId: string, isMetric: boolean , isCylinder: boolean) => void;
  getFinishSensorData: (pourId: number,sensorId: string, isMetric: boolean , isCylinder: boolean) => void;
  deleteSensor: (
    projectId: string,
    sensorId: string,
    onSuccess: () => void
  ) => void;
  updateSensor: () => void;
  addSensor: (
    sensorId: string,
    payload: AddSensorPayload,
    onSuccess: () => void,
    onFail: () => void
  ) => void;
  getEditSensorDetails:(
    pourId: number,
    sensorId: string,
    onSuccess?: (data: any) => void
  ) => void;
  getSensorDetails: (
    pourId: number,
    sensorId: string,
    onSuccess?: (data: ISensorDetails) => void
  ) => void;
  getSensorIdFromQr: (
    qrLink: string,
    onSuccess: (data: ISensorQrDetailsRes) => void
  ) => void;
  getHubIdFromQr: (
    qrLink: string,
    onSuccess: (data: IHubQrDetailsRes) => void
  ) => void;
  checkLifeSign: (
    sensorOrHubId: string,
    component: 'hub' | 'sensor',
    onSuccess: () => void,
    onFail: () => void,
    onAssigned: (data:any) => void
  ) => any;
  editSensor: (
    sensorId: string,
    data: IEditSensorPayload,
    onSuccess: () => void,
    onFail: () => void
  ) => void;
  uploadHubImage: (hubId: string, imageData: File, onFail: () => void) => void;
  addHub: (
    hubId: string,
    payload: AddHubPayload,
    onSuccess: () => void,
    onFail: () => void
  ) => void;
}

export const useSensorsStore = create(
  persist<ISensorState>(
    (set, get) => ({
      sensorData: undefined,
      sensorDetails: undefined,
      pourSensors: [],

      getPourSensors: (pourId, isFinished) => {
        handleApiCallInStore(LoadingKeys.PourSensors, async () => {
          const { data } = await http.get<ISensorItem[]>(
            API_ENDPOINTS.project.pourSensors(pourId, isFinished)
          );
          if (data) {
            set(() => ({
              pourSensors: data,
              isFinished: isFinished
            }));
          }
        });
      },
      getSensorIdFromQr: async (qrLink, onSuccess) => {
        handleApiCallInStore(
          LoadingKeys.GetSensorIdFromQr,
          async () => {
            const { data } = await http.post<ISensorQrDetailsRes>(
              API_ENDPOINTS.project.sensorIdFromQr,
              {
                path: qrLink,
              }
            );
            onSuccess?.(data);
          },
          t('pleaseScanValidSensorQr')
        );
      },
      uploadHubImage: async (hubId, imageData, onFail) => {
        handleApiCallInStore(
          LoadingKeys.UploadHubImage,
          async () => {
            const blob = new Blob([imageData], { type: 'image/jpeg' });
            http.put(API_ENDPOINTS.project.hubImageUpload(hubId), blob, {
              headers: {
                'Content-Type': 'application/octet-stream',
              },
            });
          },
          undefined,
          () => onFail?.()
        );
      },
      getHubIdFromQr: async (qrLink, onSuccess) => {
        handleApiCallInStore(
          LoadingKeys.GetHubIdFromQr,
          async () => {
            const { data } = await http.post<IHubQrDetailsRes>(
              API_ENDPOINTS.project.hubIdFromQr,
              {
                path: qrLink,
              }
            );
            onSuccess?.(data);
          },
          t('pleaseScanValidHubQr')
        );
      },
      addSensor: async (sensorId, payload, onSuccess, onFail) => {
        handleApiCallInStore(
          LoadingKeys.AddSensor,
          async () => {
            await http.post<AddSensorPayload>(
              API_ENDPOINTS.project.addSensor(sensorId),
              payload
            );
            onSuccess?.();
          },
          undefined,
          onFail
        );
      },
      addHub: async (hubId, payload, onSuccess, onFail) => {
        handleApiCallInStore(
          LoadingKeys.AddHub,
          async () => {
            await http.post<AddHubPayload>(
              API_ENDPOINTS.project.addHub(hubId),
              payload
            );
            onSuccess?.();
          },
          undefined,
          onFail
        );
      },
      editSensor: async (sensorId, data, onSuccess, onFail) => {
        handleApiCallInStore(
          LoadingKeys.EditSensor,
          async () => {
            await http.put<IEditSensorPayload>(
              API_ENDPOINTS.project.updateSensor(sensorId),
              data
            );
            if (onSuccess) {
              onSuccess?.();
            }
          },
          undefined,
          () => onFail?.()
        );
      },
      checkLifeSign: async (sensorOrHubId, component, onSuccess, onFail, onAssigned) => {
        handleApiCallInStore(LoadingKeys.CheckLifeSign, async () => {
          const { data } = await http.get<{ exist: boolean, active: boolean }>(
            API_ENDPOINTS.project.checkLifeSign(sensorOrHubId, component)
          );
          if (data?.exist) {
            if(data?.active){
              onAssigned?.(data);
            }else{
            onSuccess?.();
            }
          } else {
            onFail?.();
          }
        });
      },

      getEditSensorDetails: (pourId, sensorId, onSuccess) => 
        handleApiCallInStore(LoadingKeys.GetSensorDetails, async () => {
          console.log('!!!!!!!!!!!!!!!!')
          const { data } = await http.get<ISensorDetails>(
            API_ENDPOINTS.project.geteditsensordetails(pourId,sensorId)
          );
          if (onSuccess) {
            onSuccess(data);
          }
          set(() => ({
            sensorDetails: data,
          }));
        }),

      getSensorDetails: async (pourId, sensorId, onSuccess) =>
        handleApiCallInStore(LoadingKeys.GetSensorDetails, async () => {
          console.log('!!!!!!!!!!!!!!!!')
          const { data } = await http.get<ISensorDetails>(
            API_ENDPOINTS.project.sensorDetails(pourId,sensorId)
          );
          if (onSuccess) {
            onSuccess(data);
          }
          set(() => ({
            sensorDetails: data,
          }));
        }),
        getFinishSensorDetails: async (pourId,sensorId, onSuccess) =>
        handleApiCallInStore(LoadingKeys.GetFinishSensorDetails, async () => {
          const { data } = await http.get(
            API_ENDPOINTS.project.finshSensorDetails(sensorId,pourId)
          );
          if (onSuccess) {
            onSuccess(data);
          }
          set(() => ({
            sensorDetails: data,
          }));
        }),

        getFinishSensorData: async (pourId,sensorId, isMetric,isCylinder) => {
          handleApiCallInStore(LoadingKeys.GetFinishSensorDetails, async () => {
            const { data } = await http.get(
              API_ENDPOINTS.project.finshSensorDetails(sensorId,pourId)
            );
            let temperatureData = [];
            let strengthData = [];
            let resistivityData = []
            console.log('!!!!!!!!!!!!!!!');
            data?.sensors.historic_data.strengthHistory.forEach(sensorItem => {
              if (sensorItem.date) {
                strengthData.push({
                  date: sensorItem.date,
                  value: isMetric
                    ? isCylinder? cubeToCelinder(sensorItem.value)?.toFixed(2): sensorItem.value?.toFixed(2)
                    : isCylinder?  mpaToPsi(cubeToCelinder(sensorItem.value))?.toFixed(2) :  mpaToPsi(sensorItem.value)?.toFixed(2)
                });
              }
            });
            data?.sensors.historic_data.tempHistory.forEach(sensorItem => {
              if (sensorItem.date) {
                temperatureData.push({
                  date: sensorItem.date,
                  value: isMetric
                    ? sensorItem.value?.toFixed(2)
                    : celsiusToFahrenheit(
                        sensorItem.value
                      )?.toFixed(2),
                });
              }
            });

            set(() => ({
              sensorData: {
                displayName: data?.sensors.name,
                temperatureData,
                strengthData,
                resistivityData
              },
            }));
          });
        },

      getSensorData: async (pourId, sensorId, isMetric,isCylinder) => {
        handleApiCallInStore(LoadingKeys.GetSensorDetails, async () => {
          const { data } = await http.get<ISensorDetails>(
            API_ENDPOINTS.project.sensorDetails(pourId, sensorId)
          );
          let temperatureData = [];
          let strengthData = [];
          let resistivityData = [];
          data?.concrete_strength.forEach(sensorItem => {
            if (sensorItem.strength_derivative_1 != null || sensorItem.strength_derivative_1 != undefined) {
              strengthData.push({
                date: sensorItem.sensor_ts_utc,
                value: isMetric
                  ? isCylinder? cubeToCelinder(sensorItem.strength_derivative_1)?.toFixed(2): sensorItem.strength_derivative_1?.toFixed(2)
                  : isCylinder?  mpaToPsi(cubeToCelinder(sensorItem.strength_derivative_1))?.toFixed(2) :  mpaToPsi(sensorItem.strength_derivative_1)?.toFixed(2)
              });
            }
          });
          data?.sensor_data.forEach(sensorItem => {
            if (sensorItem.temperature_from_resistance_1) {
              temperatureData.push({
                date: sensorItem.sensor_ts_utc,
                value: isMetric
                  ? sensorItem.temperature_from_resistance_1?.toFixed(2)
                  : celsiusToFahrenheit(
                      sensorItem.temperature_from_resistance_1
                    )?.toFixed(2),
              });
            }
          });
          data?.sensor_data.forEach(sensorItem => {
            if (sensorItem.temperature_from_resistance_1) {
              resistivityData.push({
                date: sensorItem.sensor_ts_utc,
                value: sensorItem.resistivity?.toFixed(2)
              });
            }
          });
          set(() => ({
            sensorData: {
              displayName: data?.sensor_attache?.[0]?.name,
              temperatureData,
              strengthData,
              resistivityData
            },
          }));
        });
      },

      deleteSensor: async (projectId, sensorId, onSuccess) =>
        handleApiCallInStore(LoadingKeys.DeleteSensor, async () => {
          await http.delete(
            API_ENDPOINTS.project.deleteSensor(projectId, sensorId)
          );
          await onSuccess();
          const filteredPours = get().pourSensors;
          delete filteredPours[sensorId];

          set(() => ({
            pourSensors: { ...filteredPours },
          }));
        }),

      updateSensor: async () => {},
    }),
    {
      name: 'Sensors-storage',
      getStorage: () => sessionStorage,
    }
  )
);
function onAssigned() {
  throw new Error('Function not implemented.');
}

